@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ABCGintoNord';
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/ABCGintoNord-Light.woff2');
}

@font-face {
  font-family: 'ABCGintoNord';
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/ABCGintoNord-Regular.woff2');
}

@font-face {
  font-family: 'ABCGintoNord';
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/ABCGintoNord-Medium.woff2');
}

@font-face {
  font-family: 'ABCGintoNord';
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/ABCGintoNord-Bold.woff2');
}

@layer base {
  #nprogress .bar {
    @apply !bg-outrageous-orange;
  }

  #nprogress .spinner {
    position: absolute !important;
    @apply !hidden;
  }

  #nprogress .spinner-icon {
    @apply !hidden !border-t-outrageous-orange !border-l-outrageous-orange;
  }

  html,
  body {
    @apply max-w-full relative overflow-x-hidden;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  p {
    @apply mb-4;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  button {
    @apply border-none bg-transparent;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }

  .home-header .swiper-wrapper {
    @apply items-center;
  }
}

@layer components {
  .filter-select:invalid {
    @apply opacity-50;
  }

  .section {
    @apply py-12 md:py-16 lg:py-20 xl:py-24;
  }

  .hour-slider::-webkit-slider-thumb,
  .hour-slider::-moz-range-thumb,
  .hour-slider::-ms-thumb {
    @apply appearance-none h-2 w-2 bg-outrageous-orange cursor-pointer -mt-[9px] pointer-events-auto rounded-[50%];
  }

  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .shadow-text h1,
  .shadow-text h2 {
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.45);
  }

  .filter-outrageous-orange {
    filter: invert(40%) sepia(66%) saturate(1022%) hue-rotate(332deg) brightness(105%) contrast(100%);
  }

  .filter-sprout {
    filter: invert(97%) sepia(28%) saturate(404%) hue-rotate(24deg) brightness(92%) contrast(89%);
  }

  .flex-1-3 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .button {
    @apply button-base bg-sprout hover:bg-outrageous-orange border-sprout hover:border-outrageous-orange hover:text-white text-black;
  }

  .button-alt {
    @apply button-base bg-outrageous-orange hover:bg-sprout border-outrageous-orange hover:border-sprout hover:text-black text-white max-lg:mx-auto;
  }

  .button-alt-outline {
    @apply button-base bg-transparent hover:bg-outrageous-orange border-outrageous-orange hover:border-outrageous-orange hover:text-white text-outrageous-orange;
  }

  .button-white {
    @apply button-base bg-white hover:bg-sprout border-white hover:border-sprout hover:text-outrageous-orange text-black;
  }

  .button-base {
    @apply min-w-[250px] px-4 [box-shadow:0px_0px_4px_0px_rgba(0,0,0,0.25)] lg:min-w-[276px] h-[48px] lg:h-[52px] whitespace-nowrap inline-flex align-middle smooth items-center justify-center text-sm md:text-base 2xl:text-xl border-[3px] font-medium font-nord border-solid;
  }

  .content-lg {
    @apply content-headings content-lg-paragraphs;
  }

  .content {
    @apply content-headings content-paragraphs [&_iframe]:max-w-full;
  }

  .content-sm {
    @apply content-sm-headings content-sm-paragraphs;
  }

  .content-xs {
    @apply content-xs-headings content-sm-paragraphs content-quote;
  }

  .content p {
    @apply last-of-type:mb-0;
  }

  .content h1 + p,
  .content h2 + p,
  .content h3 + p,
  .content h4 + p,
  .content h5 + p,
  .content h6 + p {
    @apply mt-5;
  }

  .content p + h3 {
    @apply mt-2 lg:mt-5;
  }

  .content-headings {
    @apply children-headings:font-medium children-headings:text-mine-shaft children-headings:font-nord;
    @apply children-strong:children-headings:!font-bold;
    @apply children-h1:text-4xl xs:children-h1:text-5xl md:children-h1:text-6xl lg:children-h1:text-7xl;
    @apply children-h2:text-2xl xs:children-h2:text-3xl md:children-h2:text-4xl lg:children-h2:text-5xl children-h2:!leading-[1.2];
    @apply children-h3:text-base xs:children-h3:text-lg md:children-h3:text-xl lg:children-h3:text-2xl;
    @apply children-h4:text-sm xs:children-h4:text-base md:children-h4:text-lg lg:children-h4:text-xl;
  }

  .content-sm-headings {
    @apply children-headings:font-medium children-headings:text-mine-shaft children-headings:font-nord;
    @apply children-strong:children-headings:!font-bold;
    @apply children-h1:text-3xl xs:children-h1:text-4xl md:children-h1:text-5xl lg:children-h1:text-6xl;
    @apply children-h2:text-2xl xs:children-h2:text-2xl md:children-h2:text-3xl lg:children-h2:text-[40px] children-h2:!leading-[1.2];
    @apply children-h3:text-sm xs:children-h3:text-lg md:children-h3:text-xl lg:children-h3:text-2xl;
    @apply children-h4:text-sm xs:children-h4:text-base md:children-h4:text-lg lg:children-h4:text-xl;
  }

  .content-xs-headings {
    @apply children-headings:font-medium children-headings:text-mine-shaft children-headings:font-nord;
    @apply children-strong:children-headings:!font-bold;
    @apply children-h1:text-xl xs:children-h1:text-2xl md:children-h1:text-3xl lg:children-h1:text-[40px] lg:children-h1:!leading-[1.2];
    @apply children-h2:text-lg xs:children-h2:text-xl md:children-h2:text-2xl lg:children-h2:text-2xl xl:children-h2:text-3xl children-h2:!leading-[1.2];
    @apply children-h3:text-sm xs:children-h3:text-lg md:children-h3:text-xl lg:children-h3:text-2xl;
    @apply children-h4:text-sm xs:children-h4:text-base md:children-h4:text-lg lg:children-h4:text-xl;
  }

  .content-lg-paragraphs {
    @apply children-p:font-prompt children-p:inline-block children-p:font-light children-p:text-mine-shaft children-p:min-w-full;
    @apply children-p:text-sm sm:children-p:text-base lg:children-p:text-lg;
    @apply children-p:leading-[27px] sm:children-p:leading-[30px] lg:children-p:leading-[32px];
    @apply children-strong:children-p:text-base xs:children-strong:children-p:text-lg md:children-strong:children-p:text-xl lg:children-strong:children-p:text-2xl children-strong:children-p:font-bold children-strong:children-p:mt-4 children-strong:children-p:block;
    @apply children-strong:children-p:text-base lg:children-strong:children-p:text-lg children-strong:children-p:font-bold children-strong:children-p:mt-4;
    @apply children-p:children-blockquote:text-mine-shaft children-p:children-blockquote:mb-0 children-p:children-blockquote:text-xl lg:children-p:children-blockquote:text-[40px] lg:children-p:children-blockquote:!leading-[1.2] children-p:children-blockquote:font-nord children-p:children-blockquote:font-medium;
  }

  .content-quote {
    @apply children-blockquote:pr-8 lg:children-blockquote:pr-16 children-blockquote:before:bg-sprout children-blockquote:relative children-blockquote:before:absolute children-blockquote:before:h-full children-blockquote:before:w-32 children-blockquote:before:-left-32 children-blockquote:before:top-0 children-blockquote:py-10 lg:children-blockquote:py-16 children-p:children-blockquote:font-medium children-p:children-blockquote:font-nord children-blockquote:text-outrageous-orange children-p:children-blockquote:text-lg children-p:children-blockquote:leading-[28px]  lg:children-p:children-blockquote:text-2xl lg:children-p:children-blockquote:leading-[34px] children-blockquote:bg-sprout children-blockquote:my-4 lg:children-blockquote:my-8;
  }

  .content-paragraphs {
    @apply children-p:font-prompt children-p:inline-block children-p:font-light children-p:text-mine-shaft children-p:min-w-full;
    @apply children-p:text-sm sm:children-p:text-base lg:children-p:text-lg;
    @apply children-a:text-outrageous-orange children-a:underline;
    @apply children-p:leading-[27px] sm:children-p:leading-[30px] lg:children-p:leading-[32px];
    @apply children-strong:children-p:text-base lg:children-strong:children-p:text-lg children-strong:children-p:font-bold children-strong:children-p:mt-4;
    @apply children-p:children-blockquote:text-mine-shaft lg:children-p:children-blockquote:text-[40px] lg:children-p:children-blockquote:!leading-[1.2] children-p:children-blockquote:font-nord children-p:children-blockquote:font-medium;
  }

  .content-sm-paragraphs {
    @apply children-p:font-prompt children-p:inline-block children-p:font-light children-p:text-mine-shaft;
    @apply children-p:text-sm sm:children-p:text-base lg:children-p:text-lg;
    @apply children-a:text-outrageous-orange children-a:underline;
    @apply children-p:leading-[27px] sm:children-p:leading-[30px] lg:children-p:leading-[32px];
    @apply children-strong:children-p:font-bold;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
  }
  .text-shadow-none {
    text-shadow: none;
  }
}

/* ----------START PARSECONTENT---------- */
.parsecontent ul li,
.parsecontent ol li {
  @apply pl-7 py-1 relative font-prompt font-light text-sm sm:text-lg;
}

.parsecontent ul li:after,
.parsecontent ol li:after {
  content: url('/icons/usp-check.svg');
  @apply h-5 w-5 absolute top-1/2 left-0 -translate-y-1/2;
}

.parsecontent.content-xs a.button-alt {
  @apply text-white;
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-medium;
}
/* ----------END PARSECONTENT---------- */

/* ----------START BUTTONS---------- */

/* ----------END BUTTONS---------- */

/* ----------START FORM---------- */
.form-duuf-group {
  @apply mb-6;
}

.form-duuf-label {
  @apply font-prompt block lg:text-xl font-light py-2;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply h-14 border-silver text-lg font-prompt font-light border-[1px] border-solid w-full px-3 !shadow-none !ring-0;
}

.form-alt-contact .form-duuf-textarea {
  @apply h-[160px];
}

.form-alt-contact .form-duuf-group-submit {
  @apply mb-0;
}

.form-contact .form-duuf-group-submit {
  @apply max-lg:flex max-lg:justify-center;
}

.form-duuf-file {
  @apply flex items-center;
}

.form-duuf-file-input {
  @apply hidden;
}

.form-duuf-file-name {
  @apply ml-2 font-prompt;
}

.form-duuf-select__placeholder {
  @apply opacity-30;
}

.form-duuf-group .form-duuf-file-button > button {
  @apply button-alt-outline;

  padding: 0.1rem 0.35rem !important;
  font-size: 16px !important;
}

.form-duuf-textarea {
  @apply pt-3 h-32;
}

.form-duuf-group-consent {
}

.form-duuf-checkbox-label {
  @apply font-prompt text-xl font-light;
}

.form-duuf-group-consent {
  @apply font-prompt;
}

.form-duuf-group-consent a {
  @apply !text-base text-outrageous-orange hover:underline;
}

.form-duuf-checkbox {
  @apply mr-4 h-5 w-5 rounded-md border-none bg-white text-black focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-select__control {
  @apply h-14 !border border-silver !rounded-none !bg-white !shadow-none;
}

.form-duuf-select__single-value {
  @apply font-prompt;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer transition-all;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-black !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-white;
}

.form-duuf-select__option--is-selected {
  @apply !bg-outrageous-orange;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !top-[6px] !left-[6px] absolute !m-0 !text-black;
}

.form-duuf-select__placeholder {
  @apply !top-[6px] !left-[6px] absolute !m-0 !text-black font-prompt;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply text-[12px] text-red-700;
}

.form-duuf-submit-disabled {
  @apply opacity-50;
}

.form-alternative-button .form-duuf-submit > button {
  @apply !button;
}

.form-duuf-submit > button {
  @apply button-alt;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-50;
}

.form-duuf-group-consent:after {
  content: url('/icons/info.svg') '\00a0\00a0Wij gaan zorgvuldig om met jouw persoonsgegevens.';
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.form-duuf-group-consent span,
.form-duuf-group-consent::after {
  @apply font-light text-sm font-prompt;
}
/* ----------START FORM---------- */

/* ----------START SOLLICITATIE FORM---------- */

.rnFormContainer h2 {
  @apply hidden font-nord font-medium text-outrageous-orange text-3xl;
}

.oe_widget h2 {
  @apply font-nord font-medium text-outrageous-orange text-2xl;
}

.rnFieldList legend {
  @apply font-nord font-medium text-xl text-mine-shaft;
}

.rnFieldList fieldset {
  @apply my-6;
}

.rnFormGroup,
.oe_formElement {
  @apply mb-3 last:mb-0;
}

.rnFormGroup label,
.oe_formElement label {
  @apply font-prompt block text-xl font-light py-2;
}

.oe_phoneNumberInput {
  @apply w-full;
}

.oe_phoneNumberInput {
  @apply !flex items-center gap-3;
}

.oe_selectorValue {
  @apply hidden;
}

.rnFormGroup input[type='text'],
.rnFormGroup input[type='email'],
.rnFormGroup input[type='number'],
.rnFormGroup input[type='tel'],
.rnFormGroup textarea,
.oe_formElement input[type='text'],
.oe_formElement input[type='email'],
.oe_formElement input[type='number'],
.oe_formElement input[type='tel'],
.oe_formElement textarea {
  @apply h-14 border-silver text-lg font-prompt font-light border-[1px] border-solid w-full px-3 !shadow-none !ring-0;
}

.rnFormGroup textarea {
  @apply pt-3 h-32;
}

.rnDescription {
  @apply font-prompt font-light text-lg;
}

.rnDescription a {
  @apply text-outrageous-orange underline;
}

.rnprivacystatementField {
  @apply flex;
}

.rnprivacystatementField .rnFieldWrap {
  @apply w-[50px];
}

.rnprivacystatementField label {
  @apply font-prompt font-light text-lg;
}

.rnButton,
.oe_buttons button {
  @apply bg-outrageous-orange hover:bg-sprout border-2 font-medium font-nord border-solid border-outrageous-orange hover:border-sprout text-sm sm:text-base lg:text-xl hover:text-outrageous-orange text-white focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-outrageous-orange py-1 md:py-2 px-1 sm:px-2 md:px-4 transition-all duration-200;
}

/* ----------END SOLLICITATIE FORM---------- */

.swiper-button-next,
.swiper-button-prev {
  @apply !text-outrageous-orange;
}

.custom-swiper .swiper-slide {
  @apply w-[31%] h-[600px] lg:h-[750px] xl:h-[700px] z-10 flex items-center;
}

.custom-swiper .swiper-slide .custom-slide-description {
  @apply children-p:leading-[24px] 2xl:children-p:leading-[26px] children-p:text-xs;
}

.custom-swiper .swiper-slide > div {
  @apply lg:h-[80%] xl:h-[70%] smooth z-10;
}

.custom-swiper .swiper-slide-next {
  @apply z-30;
}

.custom-swiper .swiper-slide-next > div {
  @apply !h-full relative z-30;
}

.custom-swiper .swiper-slide-next::before {
  @apply lg:content-[''] w-20 my-auto h-[80%] xl:h-[70%] z-20 mix-blend-multiply absolute bottom-0 top-0 -right-10 [background:linear-gradient(90deg,rgba(0,0,0,0.5)_3.99%,rgba(0,0,0,0)_96.44%)];
}

.custom-swiper .swiper-slide-next::after {
  @apply lg:content-[''] w-20 my-auto h-[80%] xl:h-[70%] z-20 mix-blend-multiply absolute bottom-0 top-0 -left-10 [background:linear-gradient(270deg,rgba(0,0,0,0.5)_4.38%,rgba(0,0,0,0)_89.33%)];
}

.custom-swiper .swiper-slide-next .custom-slide-description {
  @apply children-p:leading-[28px] 2xl:children-p:leading-[32px] children-p:text-xs 2xl:children-p:text-base;
}

.swiper-button-disabled {
  @apply opacity-30 pointer-events-none cursor-not-allowed;
}

#werk-text p:not(:first-child) {
  display: none;
}
